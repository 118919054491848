import {useState} from "react";

export function useVar(arg = undefined) {
    const [val, setVal] = useState(arg);
    return {
        get val() {
            return val;
        },
        set val(v) {
            setVal(_ => v);
        },
        set(v) {
            setVal(_ => v)
        }
    }
}