import {useRef} from 'react';
import {useCountUp} from 'react-countup';

import {RefObject, useEffect, useState} from 'react'

function useIntersectionObserver(
    elementRef,
    {
        threshold = 0,
        root = null,
        rootMargin = '0%',
        freezeOnceVisible = false,
    },
) {
    const [entry, setEntry] = useState();

    const frozen = entry?.isIntersecting && freezeOnceVisible

    const updateEntry = ([entry]) => {
        setEntry(entry)
    }

    useEffect(() => {
        const node = elementRef?.current // DOM Ref
        const hasIOSupport = !!window.IntersectionObserver

        if (!hasIOSupport || frozen || !node) return

        const observerParams = {threshold, root, rootMargin}
        const observer = new IntersectionObserver(updateEntry, observerParams)

        observer.observe(node)

        return () => observer.disconnect()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

    return entry
}

export default useIntersectionObserver;


export function useCountAnimation(startValue = 0, end, delay = 0, format = s => (s).toString()) {
    const elementRef = useRef();
    const [isRunning, setIsRunning] = useState(false);
    const entry = useIntersectionObserver(elementRef, {threshold: 1});

    const {start, pauseResume, reset, update} = useCountUp({
        ref: elementRef,
        start: startValue,
        end,
        delay: delay/2,
        duration: 1 + (end - startValue) / 50,
        suffix: "+",
        // enableScrollSpy: true,
        // onReset: () => console.log('Resetted!'),
        // onUpdate: () => console.log('Updated!'),
        // onPauseResume: () => console.log('Paused or resumed!'),
        // onStart: ({pauseResume}) => console.log(pauseResume),
        onEnd: ({pauseResume}) => setIsRunning(false),
    });

    useEffect(() => {
        if (!isRunning && entry && entry.isIntersecting) {
            start();
            setIsRunning(true);
        }
        if(entry && entry.intersectionRatio === 0){
            reset();
            setIsRunning(false);
        }
    }, [entry, setIsRunning]);

    return elementRef;
    /*ReactDOM.findDOMNode()

    const num = useRef();
    const lastTime = useRef();
    const elementRef = useRef();
    const startMe = () => requestAnimationFrame(delta => {
        if (intersection && Date.now() - lastTime.current >= stepMinMs)
            startMe();
        elementRef.innerText = format(num.current);
        num.current += 1;
        lastTime.current += Date.now();
    });
    elementRef.current && startMe();
    return [elementRef, /!*startMe*!/];*/

}
