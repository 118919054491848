import RawApiDataTextArea from "./RawApiDataTextArea";
import ErrorBoundary from "./ErrorBoundary";
import {useGetData} from "./api";
import {Arrow, LinkedInIcon, LocationIcon, MailIcon, PhoneIcon} from "./icons";
import useEventListener from "@use-it/event-listener";
import {useVar} from "./useVar";
import {ifdev} from "./ifdev";
import useSliderDialog from "./SliderDialog";
import {useEmblaWrapper} from "./EmblaCarousel1";

import './HeroSectionGrid.css'
import './single-cell-grid.css'
import {RichTextDiv} from "./RichTextDiv";
import EmblaCarousel2 from "./EmblaCarousel2";
import {MyDropdown} from "./DropDownMenu";
import {slugFromNavText} from "./slugFromNavText";
import {imgSizes} from "./imageResize";
import * as PropTypes from "prop-types";
import {useCountAnimation} from "./countanim";

function SocialIconLinks({data}) {
    return <>
        {data.linkedin && <a href={data.linkedin}><LinkedInIcon/></a>}
        {data.telephone && <LinkTelIcon tel={data.telephone}/>}
        {data.email[0] && <LinkEmailIcon email={data.email[0]}/>}
    </>;
}

function assetUrl({path}) {
    return `${ifdev("", "")}/cockpit/storage/uploads${path}`;
}

function MobileNavBar({data}) {
    return <nav
        className="lg:hidden fixed flex items-center justify-between bg-white p-4 py-2 w-full z-50">
        {/*<div className="flex gap-6 items-center justify-start">*/}
            <img className={"max-w-[50%] max-h-12 py-1"} src={data.s10_hero[0].navbar_logo && assetUrl(data.s10_hero[0].navbar_logo)}/>
            {/*{Object.values(data).map(([s], i) => s.nav_text &&
                <a key={i}
                   href={"#" + slugFromNavText(s.nav_text)}
                   className="vertical-rl uppercase rotate-180 text-base text-gray-300 hover:text-gray-600">{s.nav_text}</a>)}*/}
        {/*</div>*/}
            {/*className="flex flex-col gap-6 items-center justify-end [&_svg]:opacity-70 [&_svg:hover]:opacity-100">*/}
            {/*<SocialIconLinks data={data.s80_contact[0]}/>*/}
            <MyDropdown data={data}/>
    </nav>;
}

function NavBar({data}) {
    return <nav
        className="max-lg:hidden sticky flex flex-col items-center justify-between h-screen bg-white p-4 py-6 w-max">
        <div className="flex flex-col-reverse gap-6 items-center justify-start">
            {Object.values(data).map(([s], i) => s.nav_text &&
                <a key={i}
                   href={"#" + slugFromNavText(s.nav_text)}
                   className="vertical-rl uppercase rotate-180 text-base text-gray-300 hover:text-gray-600">{s.nav_text}</a>)}
        </div>
        <div
            className="flex flex-col gap-6 items-center justify-end [&_svg]:opacity-70 [&_svg:hover]:opacity-100">
            <SocialIconLinks data={data.s80_contact[0]}/>
        </div>
    </nav>;
}

function HeroSection({data}) {

    const slides = data.services
        .map(s => s.slider_image);
    // .concat(["https://via.placeholder.com/1920x1080", "https://via.placeholder.com/1920x1080"]);

    const {viewportRef, embla, buttons} = useEmblaWrapper();

    const index = 0;

    const line1_line2_ratio = 25/7;
    const line1_fontSize = "min(25vh,25vw)";
    const line2_fontSize = `calc(${line1_fontSize}/${line1_line2_ratio})`;

    return <>
        <section className={"hero-section dark roboto hero-grid h-screen"}>
            <div className="hero-grid-full z-0">
                <div className="embla__viewport h-full max-h-screen" ref={viewportRef}>
                    <div className="embla__container noselect opacity-20 h-full max-h-screen">
                        {slides.map((asset, i) => (
                            <div className="embla__slide" key={i}>
                                <div className="embla__slide__inner h-full max-h-screen">
                                    <img
                                        className="embla__slide__img object-cover w-full h-full max-h-screen"
                                        src={assetUrl(asset)}
                                        loading={i === 0 ? "eager":"lazy"}
                                        // alt={"Hintergrund"}
                                        {...imgSizes(asset, 2048, 5)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                className={"hero-grid-full justify-self-stretch self-center relative max-md:portrait:top-[20%] " +
                    "flex flex-col items-end gap-1 " +
                    "text-white border-white z-10"}>
                <span
                    className={"mr-6 text-lg md:text-2xl leading-8"}>{((embla && embla.selectedScrollSnap()) + 1).toString().padStart(2, "0")}</span>
                <div className={"self-stretch border-b-2"}/>
                <span
                    className={"mr-7 text-xs md:text-lg leading-8"}>{(data.services.length).toString().padStart(2, "0")}</span>
            </div>
            <div style={{fontSize: line1_fontSize}}
                className={`max-md:text-center md:ml-10 self-end ` +
                    "hero-grid-top relative max-md:portrait:top-[-10%] " +
                    "leading-none z-20 apex"}>
                <span>{data.line1}</span>
            </div>
            <div style={{fontSize: line2_fontSize}}
                className={`max-md:text-center md:ml-10 self-start ` +
                    "hero-grid-bottom relative max-md:portrait:top-[-10%] " +
                    "z-20 apex"}>
                <span>{data.line2}</span>
            </div>
            <div className={"hero-grid-bottom self-end sm:justify-self-end " +
                "flex items-center justify-between gap-12 p-6 bg-gray-100 w-auto sm:w-max min-w-[35%] max-w-full " +
                "m-[5%] h-max relative"}>
                <div className="inline-flex flex-col items-start h-full">
                    <p className="text-xs uppercase tracking-[0.2em] text-gray-400">Angebote</p>
                    <div className={"grid single-cell"}>{data.services.map((s, i) =>
                        <p
                            className={`text-md sm:text-lg md:text-xl uppercase font-bold text-gray-800 hero-service-slider-text ${embla && i === embla.selectedScrollSnap() ? "" : "invisible"}`}>
                            {s.slider_name}
                        </p>)}</div>

                </div>
                {/*<div className={"absolute top-[0] right-[0] bottom-[0] aspect-square"}>*/}
                {/*<div className={"flex items-center gap-4 text-gray-800 absolute top-[50%] right-[50%] bottom-[50%]"}>*/}
                <div className={"flex items-center gap-4 text-gray-800 mr-1"}>
                    {/* TODO: these buttons don't have the same spacing to the right border as they have to the top and bottom! */}
                    {/* Idea: Add another square (aspect ratio 1) (inside outer square) that is centered and takes height of content (arrows). */}
                    {buttons}
                </div>
                {/*</div>*/}
            </div>
        </section>
        <RawApiDataTextArea data={data}/>
    </>;
}

function H3({html}) {
    return <h3 dangerouslySetInnerHTML={{__html: html}} className={"heading_with_clrtxt"}/>;
}

function Stat({name, value, delay}) {
    const r = useCountAnimation(0, value, delay, i => `${i}+`);
    return <dl className={"flex flex-col-reverse"}>
        <dt className="text-xl tracking-widest text-gray-500 uppercase tracking-[0.2em]">{name}</dt>
        <dd className="text-7xl md:text-8xl font-bold text-teal" ref={r}>{/*{value}+*/}</dd>
    </dl>;
}


function AboutSection({data}) {
    return <section id={data.nav_text && slugFromNavText(data.nav_text)} className={"roboto"}>
        <div className="section-content">
            <RawApiDataTextArea data={data} small/>
            <h1>{data.heading}</h1>
            <H3 html={data.subheading}/>
            <div className={"flex flex-col md:grid"}
                 style={{gap: 'calc(10% + 30px)', gridTemplateColumns: '2fr 2fr'}}>
                <RichTextDiv html={data.col1} className={"fullwidth-image text-content"}/>
                <div className={"flex flex-col gap-8"} style={{flexBasis: '300px'}}>
                    {data.stats.map((stat, i) =>
                        <Stat key={i} name={stat.name} value={stat.value} delay={i}/>
                    )}
                    <RichTextDiv html={data.col2} className={"text-content"}/>
                </div>
            </div>
        </div>
    </section>;
}

function ValuesSection({data}) {
    const gradientBg = [/*"bg-teal-9", "bg-teal-8", */"bg-teal-7", "bg-teal-6", "bg-teal-5", "bg-teal-4", "bg-teal-3", "bg-teal-2", "bg-teal-1", "bg-teal-0",];
    const gradientText = [/*"bg-teal-9", "bg-teal-8", */"", "", "", "text-teal-7", "text-teal-8", "text-teal-7", "text-teal-6", "text-teal-5",];
    return <section id={data.nav_text && slugFromNavText(data.nav_text)}
                    style={{
                        backgroundImage: `-webkit-cross-fade(linear-gradient(#03989E, #03989E), url(${assetUrl(data.background_image)}), 30%)`,
                        backgroundSize: "cover",
                        paddingBottom: 0
                    }}
                    className={"dark roboto min-h-screen overflow-clip"}>
        <RawApiDataTextArea data={data}/>
        <div
                className={"section-content " +
                    // "grid grid-rows-[auto,1fr] grid-cols-[max-content_1fr] " +
                    "grid grid-rows-[auto,auto,1fr] sm:grid-rows-[auto,1fr] sm:grid-cols-[max-content_1fr] " +
                    "gap-6 sm:gap-12 " +
                    "mb-0 sm:pt-16 " +
                    "mr-0 ml-[var(--section-content-margin-x)] w-auto "}
            style={{paddingRight: 0}}
        >
            <h2 className={"h2special roboto tracking-[1px] leading-[1.8] relative mt-1 pr-8 font-[500] text-base w-max"}>{data.heading}</h2>
            <RichTextDiv html={data.text}
                         className={"w-[var(--section-content-container-width)] max-w-sm sm:[&_p]:pr-12 mb-8 text-content"}/>
            <ul className={"col-span-full text-center flex overflow-x-auto xl:overflow-x-hidden"}> {/* TODO auto-fit */}
                {data.values.map((v, i) => <li key={i}
                                               className={`px-5 flex-1 min-w-[20rem] xl:min-w-[16rem] ${""/*gradientText[i]*/} ${gradientBg[i]}`}>
                    <img src={v.image && assetUrl(v.image)}
                         {...imgSizes(v.image, 512, 100, "(min-width: 0px) 256px")}
                         className={"mx-auto my-4 h-[20%] aspect-square"}/>
                    <h4 className={"uppercase"}>{v.name}</h4>
                    <RichTextDiv html={v.text} className={"mb-8"}/>
                </li>)}
            </ul>
        </div>
    </section>;
}

function ServicesSection({data}) {
    const [openDialog, dlg] = useSliderDialog(data.heading,
        data.services.map(s => ({heading: s.name, content: s.text}))
    );

    return <section id={data.nav_text && slugFromNavText(data.nav_text)}>
        <RawApiDataTextArea data={data}/>
        <div className="section-content ">
            <h2>{data.heading}</h2>
            <H3 html={data.subheading}/>
            <div className={"flex justify-center"}>
                <ol className={"grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-stretch"}>{data.services.map((s, i) =>
                    <li key={i} className={"aspect-square " +
                        "py-6 px-8 relative text-sm " +
                        "group " +
                        "outline outline-1 outline-neutral-300 hover:outline-teal/40 hover:outline-4 " +
                        "hover:text-white hover:bg-teal"}>
                        <img
                            className={"filter group-hover:invert group-hover:opacity-90 float-right max-h-16 max-w-24"}
                            {...imgSizes(s.image, 512, 100, "(min-width: 0px) 128px")}
                            src={assetUrl(s.image)}/>
                        {/*<div>*/}
                        <h5 className={"mt-8 hyphens-auto"} style={{
                            overflowWrap: "break-word",
                            wordBreak: "break-word"
                        }}>{s.name}</h5>
                        <p className={"leading-loose"}>{s.short_description}</p>
                        <a href={"#"} onClick={e => {
                            openDialog(i);
                            e.preventDefault();
                            return false;
                        }}
                           className={"absolute bottom-0 left-0 py-6 px-8 flex w-full justify-between uppercase font-bold clrtxt group-hover:text-white"}>
                            {"Details"}<Arrow right/>
                        </a>
                        {/*</div>*/}
                    </li>
                )}</ol>
            </div>
        </div>
        {dlg}
        {/*<EmblaCarousel2 initialIndex={0} slides={data.services.map(s => ({heading: s.name, content: s.text}))}/>*/}
    </section>;
}

function TtSlider({slideData}) {
    const {viewportRef, embla, buttons} = useEmblaWrapper(true);
    return <div className={"tt-slider embla"}>
        <div className="embla__viewport" ref={viewportRef}>
            <div className="embla__container">
                {slideData.map((m, i) => (
                    <div className="embla__slide" key={i}>
                        <div className="embla__slide__inner flex flex-col md:flex-row">
                            <RawApiDataTextArea data={m}/>
                            <img
                                className="embla__slide__img object-contain max-h-80 md:max-h-full md:max-w-[50%]"
                                src={assetUrl(m.image)}
                                {...imgSizes(m.image, 2048, 100, {sm: "50vw", xl: "512px"})}
                            />
                            <div className={"md:px-8 pt-8 md:py-4 flex-1"}>
                                <h4 className={"clrtxt"}>{m.title}</h4>
                                <p className={"text-2xl leading-9 text-gray-800 roboto mb-6"}>{m.description}</p>
                                <RichTextDiv html={m.text}
                                             className={"bg-[#EDEDED] text-lg leading-loose text-gray-700 roboto pt-4 pb-8 px-8 md:-mx-8"}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div
            className={"flex mx-auto mt-4 w-max " +
                "border border-teal rounded-full md:bg-gray-100 justify-center md:justify-end md:absolute bottom-0 right-0"}>
            {buttons}
        </div>
    </div>;
}


function TestimonialsSection({data}) {
    return <section id={data.nav_text && slugFromNavText(data.nav_text)}>
        <div className="section-content">
            <h2>{data.heading}</h2>
            <H3 html={data.subheading}/>
            <TtSlider slideData={data.testimonials}/>
        </div>
    </section>;
}

function TeamSection({data}) {
    return <section id={data.nav_text && slugFromNavText(data.nav_text)}>
        <div className="section-content">
            <h1>{data.heading}</h1>
            <H3 html={data.subheading}/>
            <TtSlider slideData={data.members}/>
        </div>
    </section>;
}

function FaqSection({data}) {
    const [openDialog, dlg] = useSliderDialog(data.heading,
        data.questions.map(s => ({heading: s.name, content: s.text}))
    );

    return <section id={data.nav_text && slugFromNavText(data.nav_text)} className={"dark"}>
        <div className="section-content">
            <RawApiDataTextArea data={data}/>
            <h1>{data.heading}</h1>
            <H3 html={data.subheading}/>
            <div className={"flex justify-center"}>
                <ul className={"grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 items-stretch"}>{data.questions.map((s, i) =>
                    <li key={i} className={"aspect-square " +
                        "py-6 px-8 max-w-sm relative text-sm " +
                        "group " +
                        "outline outline-1 hover:outline-white/40 outline-white/40 hover:outline-4 " +
                        "hover:text-gray-800 hover:bg-gray-100"}>
                        <img
                            className={"block max-h-20 max-w-20 mb-4 " +
                                "filter group-hover:invert group-hover:opacity-90"}
                            {...imgSizes(s.image, 512, 100, "(min-width: 0px) 128px")}
                            src={assetUrl(s.image)}/>
                        {/*<div>*/}
                        <h5 className={"text-lg hyphens-auto"} style={{
                            overflowWrap: "break-word",
                            wordBreak: "break-word"
                        }}>{s.name}</h5>
                        <p className={"leading-loose"}>{s.short_description}</p>
                        <a href={"#"} onClick={e => {
                            openDialog(i);
                            e.preventDefault();
                            return false;
                        }}
                           className={"absolute bottom-0 left-0 py-6 px-8 flex w-full justify-between uppercase font-bold " +
                               "bg-gradient-to-t from-teal group-hover:from-gray-100 via-teal group-hover:via-gray-100 to-transparent text-white group-hover:text-teal"}>
                            {"Antwort"}<Arrow right/>
                        </a>
                        {/*</div>*/}
                    </li>
                )}</ul>
            </div>
        </div>
        {dlg}
    </section>;
}

function ContactSection({data}) {
    return <section id={data.nav_text && slugFromNavText(data.nav_text)}>
        <RawApiDataTextArea data={data}/>
        <div className="section-content text-xl">
            <h2>{data.heading}</h2>
            <H3 html={data.subheading}/>
            <div className={"grid lg:grid-cols-2 gap-y-24 gap-x-[max(10%,1rem)]"}>
                <div>
                    <form action={ifdev("contact.php", "contact.php")}
                          method={"post"} className={"grid grid-cols-2 gap-6"}>
                        {/*<label>{"Vor - & Nachname"}</label>*/}
                        <input type="text" required id="name" name="name"
                               placeholder="Vor - & Nachname" className={"col-span-full"}/>
                        {/*<label>Telefon</label>*/}
                        {/*<label>Email Addresse</label>*/}
                        {/*<label>Email</label>*/}
                        <input type="tel" id="phone" name="phone" placeholder="Telefon"/>
                        <input type="email" id="email" name="email" placeholder="Email-Adresse"/>

                        {/*<label>Nachricht</label>*/}
                        <textarea required id="message" name="message" placeholder="Nachricht"
                                  className={"min-h-[10em] col-span-full"}></textarea>
                        <input type="submit" value="Senden" className={"col-span-full"}/>
                    </form>
                </div>
                <div className={"flex flex-col gap-8 [&_dt]:text-[#A9A9A9] [&_dd]:font-bold"}>
                    <dl>
                        <dt>Adresse</dt>
                        <dd><LinkLocation address={data.address}/></dd>
                    </dl>
                    <dl>
                        <dt>Email</dt>
                        {data.email.map((e, i) => <dd key={i}><LinkEmailText email={e}/></dd>)}
                    </dl>
                    <dl>
                        <dt>Telefon</dt>
                        <dd>{data.telephone && <LinkTelText tel={data.telephone}/>}</dd>
                    </dl>
                </div>
            </div>
        </div>
    </section>;
}

function FooterSection({data}) {
    return <footer className={"dark"}>
        <div className={"p-10"}>
            <RawApiDataTextArea data={data}/>

            <div className={"flex flex-wrap justify-between gap-8"}>
                <RichTextDiv html={data.col1} className={"footer-logo-column"}/>
                <div className={"grid grid-cols-[auto,1fr] gap-y-4 gap-x-4 relative"}>
                    <div className={"max-sm:hidden"}/>
                    <h4 className={"max-sm:col-span-full"}>Kontakt</h4>
                    {data.telephone &&
                        <dl className={"contents ml-12 md:ml-0"}>
                            <dt className={""}><PhoneIcon/></dt>
                            <dd><LinkTelText tel={data.telephone}/></dd>
                        </dl>
                    }
                    {data.address &&
                        <dl className={"contents ml-12 md:ml-0"}>
                            <dt className={""}><LocationIcon/></dt>
                            <dd className={"whitespace-pre-wrap"}><LinkLocation
                                address={data.address}/>
                            </dd>
                        </dl>
                    }
                </div>
                <div className={"flex flex-col gap-4 md:text-right relative"}>
                    <h4>Unternehmen</h4>
                    <a href={"/impressum"} className={"uppercase font-bold"}>Impressum</a>
                    <a href={"/datenschutz"} className={"uppercase font-bold"}>Datenschutz</a>
                </div>
            </div>
            <div className={"border-white border my-8"}/>
            <div className={"flex justify-between"}>
                <div>
                    LAOR © WEGO
                    <br/>
                    All rights reserved Copyrights 2022
                </div>
                <div className="flex gap-6 items-center justify-end fill-white">
                    <SocialIconLinks data={data}/>
                </div>
            </div>
        </div>
    </footer>;
}

export function AllSections() {
    const {data: queryData, isLoading, error} = useGetData();
    const previewData = useVar();
    useEventListener("message", ({data: msgData}) => {
        if (msgData.event === "cockpit:content.preview") {
            previewData.val = {[msgData.context.model]: [msgData.data]};
        }
    });
    const data = (queryData ? {...queryData, ...(previewData.val || {})} : undefined);
    // console.log("data", data, "previewData", previewData.val);
    // console.log(dlg.props);
    // const [btn, dlg] = (<SliderDialog/>).props.children;
    return !data ? <div className={"grid place-items-center h-screen text-center"}>
        {error
            ? <p className={"text-[red]"}>Fehler!<br/><code>{error.toString()}</code></p>
            : isLoading
                ?
                <p className={""}>Inhalte werden geladen...</p>
                : <p>Es scheint keinen Inhalt zu geben.</p>}
    </div> : <>
        <div className={"lg:grid h-screen wrapper"}>
            <ErrorBoundary>
                <MobileNavBar data={data}/>
                <NavBar data={data}/>
            </ErrorBoundary>
            <main className={"h-full max-h-full overflow-y-scroll"}>
                <ErrorBoundary><HeroSection data={{...data.s40_services[0], ...data.s10_hero[0]}}/></ErrorBoundary>
                <ErrorBoundary><AboutSection data={data.s20_about[0]}/></ErrorBoundary>
                <ErrorBoundary><ValuesSection data={data.s30_values[0]}/></ErrorBoundary>
                <ErrorBoundary><ServicesSection data={data.s40_services[0]}/></ErrorBoundary>
                <ErrorBoundary><TestimonialsSection
                    data={data.s50_testimonials[0]}/></ErrorBoundary>
                <ErrorBoundary><TeamSection data={data.s60_team[0]}/></ErrorBoundary>
                <ErrorBoundary><FaqSection data={data.s70_faq[0]}/></ErrorBoundary>
                <ErrorBoundary><ContactSection data={data.s80_contact[0]}/></ErrorBoundary>
                <ErrorBoundary><FooterSection
                    data={{...data.s80_contact[0], ...data.s99_footer[0]}}/></ErrorBoundary>
                {/*{btn}*/}
            </main>
        </div>
    </>;
}


function LinkTel({tel, children}) {
    return <a href={`tel:${tel.replace(/\s/g, "")}`}>{children}</a>
}

function LinkTelText({tel}) {
    return <LinkTel tel={tel}>{tel}</LinkTel>;
}

function LinkTelIcon({tel}) {
    return <LinkTel tel={tel}> <PhoneIcon solid/> </LinkTel>
}

function LinkEmail({email, children}) {
    return <a href={`mailto:${email}`}>{children}</a>
}

function LinkEmailText({email}) {
    return <LinkEmail email={email}>{email}</LinkEmail>
}

function LinkEmailIcon({email}) {
    return <LinkEmail email={email}><MailIcon solid/></LinkEmail>
}

function LinkLocation({address}) {
    return <a href={`https://www.google.com/maps/search/${address.replace(/\n/g, " ")}`}
              className={"whitespace-pre-wrap"}>{address}</a>
}
