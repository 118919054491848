import React, {useCallback, useEffect, useState} from "react";
import {NextButton, PrevButton} from "./EmblaCarouselButtons";
import useEmblaCarousel from "embla-carousel-react";
import "./embla.css";
import {Arrow} from "./icons";

function useRerender(){
    const [, setState] = useState(0);
    return () => setState(i => i+1);
}

export function useEmblaWrapper(longArrows=false) {
    const rerender = useRerender();
    const [viewportRef, embla] = useEmblaCarousel({skipSnaps: false});
    // const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    // const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    // const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);

    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
        embla
    ]);

    /*const onSelect = useCallback(() => {
        if (!embla) return;
        rerender();/!*
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());*!/
    }, [embla,]);*/

    useEffect(() => {
        if (!embla) return;
        const onSelect = () => rerender();
        // onSelect();
        // setScrollSnaps(embla.scrollSnapList());
        embla.on("select", onSelect);
    }, [embla,]);

    const buttons = <>
        {/*<PrevButton onClick={() => embla && embla.scrollPrev()}
                    enabled={embla && embla.canScrollPrev()}/>
        <NextButton onClick={() => embla && embla.scrollNext()}
                    enabled={embla && embla.canScrollNext()}/>*/}
        <button
            className="embla__button embla__button--prev"
            onClick={() => embla && embla.scrollPrev()}
            disabled={!(embla && embla.canScrollPrev())}
        >
            <Arrow left long={longArrows}/>
        </button>
        <button
            className="embla__button embla__button--next"
            onClick={() => embla && embla.scrollNext()}
            disabled={!(embla && embla.canScrollNext())}
        >
            <Arrow right long={longArrows}/>
        </button>
    </>;
    return {viewportRef, embla, buttons};
}

const EmblaCarousel1 = ({ slides }) => {
    const {viewportRef, embla, buttons} = useEmblaWrapper();

    return (
        <>
            <div className="embla">
                <div className="embla__viewport" ref={viewportRef}>
                    <div className="embla__container opacity">
                        {slides.map((slide, i) => (
                            <div className="embla__slide" key={i}>
                                <div className="embla__slide__inner">
                                    {/*<img*/}
                                    {/*    className="embla__slide__img"*/}
                                    {/*    src={slide}*/}
                                    {/*    alt="A cool cat."*/}
                                    {/*/>*/}
                                    {slide}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {buttons}
            </div>
            {embla && embla.selectedScrollSnap() + 1}/{slides.length}
        </>
    );
};

export default EmblaCarousel1;
