import {Menu, Transition} from '@headlessui/react'
import {slugFromNavText} from "./slugFromNavText";
import {Fragment} from "react";

export function MyDropdown({data}) {
    return (
        <Menu as={"div"} className={"z-50 relative"}>
            <Menu.Button className={"text-right p-2"}>
                <svg width="2rem" height="2rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="15" stroke="#595959"/>
                    <line x1="9" y1="12.5" x2="22" y2="12.5" stroke="#595959"/>
                    <line x1="9" y1="16.5" x2="22" y2="16.5" stroke="#595959"/>
                    <line x1="9" y1="20.5" x2="22" y2="20.5" stroke="#595959"/>
                </svg>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items as={"ul"}
                           className="absolute right-0 mr-2 mt-8 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {Object.values(data).map(([s], i) => s.nav_text &&
                    <Menu.Item as={"li"} className={"py-4 px-4"}>
                        <a key={i}
                           href={"#" + slugFromNavText(s.nav_text)}
                           className="uppercase text-base text-gray-400 hover:text-gray-800">{s.nav_text}</a>
                    </Menu.Item>
                )}
            </Menu.Items>
            </Transition>
        </Menu>
    )
}