import './App.css';

import {QueryClient, QueryClientProvider} from "react-query";
import {AllSections} from "./AllSections";

const queryClient = new QueryClient();

// TODO: https://stackoverflow.com/questions/49429906/how-should-i-configure-create-react-app-to-serve-app-from-subdirectory

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AllSections/>
        </QueryClientProvider>
    );
}

export default App;
