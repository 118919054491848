import {isdev} from "./ifdev";

export default function RawApiDataTextArea({data, small}) {
    return localStorage.getItem("debug") ? <div className="bg-gray-200 border-t-2 border-gray-400 text-black max-h-screen overflow-auto"
                          style={{
                              width: "100%",
                              whiteSpace: 'pre-wrap',
                              // minHeight: small ? "max-content" : "100vh",
                              fontFamily: "monospace"
                          }}
    >{JSON.stringify(data, null, 2)}</div> : undefined;
}