import {gql, GraphQLClient} from "graphql-request";
import {useQuery} from "react-query";

export const API_URL = `${process.env.NODE_ENV === 'development' ? "" : ""}/cockpit/api`;
export const GQL_API_URL = `${API_URL}/gql`;
// const query = loader('../all.gql');
const query = gql`query MainQuery {  ${[
        's10_hero',
        's20_about',
        's30_values',
        's40_services',
        's50_testimonials',
        's60_team',
        's70_faq',
        's80_contact',
        's99_footer',
    ].map(m => `\t${m}: content (model: "${m}")`).join("\n")}
}`;
const graphQLClient = new GraphQLClient(GQL_API_URL);

export function useGetData() {
    // console.log("query-url", API_URL);
    // console.log("query", query);
    return useQuery("get-data", async () => {
        const response = await graphQLClient.request(query);
        // console.log("GQL Response:", response);
        return response;
    });
}

/*
export function useGetData() {
    console.log("query", query);

    // const sData = useState
    let resolve = null;
    const dataPromise = new Promise(res => {
        resolve = res
    });
    useEventListener("message", (msg) => {
        resolve(msg.data);
    });

    return useQuery("get-posts",
        window.location.search.includes("preview=true")
            ? async () => {
                const msgData = await dataPromise;
                return {[msgData.context.model]: [msgData.data]};
            }
            : async () => {
                const response = await graphQLClient.request(query);
                console.log("GQL Response:", response);
                return response;
            }
    );
}*/
