import React, {useState, useEffect, useCallback} from "react";
import {DotButton, PrevButton, NextButton} from "./EmblaCarouselButtons";
import useEmblaCarousel from "embla-carousel-react";
import "./embla.css";
import {useEmblaWrapper} from "./EmblaCarousel1";
import {RichTextDiv} from "./RichTextDiv";

function EmblaCarousel2({initialIndex, slides}) {
    const {viewportRef, embla, buttons} = useEmblaWrapper();

    useEffect(() => {
        embla && embla.reInit();
        embla && embla.scrollTo(initialIndex, true);
    }, [embla, initialIndex]);

    // console.log(embla && embla.scrollSnapList());
    return (
        <>
            <div className="embla">
                <div className="embla__viewport" ref={viewportRef}>
                    <div className="embla__container">
                        {slides.map((slide, i) => (
                            <div className="embla__slide" key={i}>
                                <div className="embla__slide__inner">
                                    <div className={"float-right flex gap-4"}><div className={"embla__button placeh"}/><div className={"embla__button placeh"}/></div>
                                    <h4 className={"clrtxt hyphens-auto"}>{slide.heading}</h4>
                                    <RichTextDiv html={slide.content}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={"absolute right-0 top-0 flex gap-4 m-8"}>{buttons}</div>
            <div className="embla__dots absolute right-0 bottom-0 m-8 md:mb-12">
                {embla && embla.scrollSnapList().map((_, index) => (
                    <DotButton
                        key={index}
                        selected={index === embla.selectedScrollSnap()}
                        onClick={() => embla.scrollTo(index)}
                    />
                ))}
            </div>
        </>
    );
}

export default EmblaCarousel2;
