import {API_URL} from "./api";

const twSizes = {
    sm: '640',
    md: '768',
    lg: '1024',
    xl: '1280',
    '2xl': '1536',
};
export function imgSizes(img, maxw = 4096, quality = 100, sizesMapOrString= "") {
    const sizesString = typeof sizesMapOrString === "string"
        ? sizesMapOrString
        : Object.entries(sizesMapOrString).map(([k,v]) =>
            `(min-width: ${twSizes[k] || k}px) ${v}`
        ).join(", ");
    // const [imgSrcSet, setImgSrcSet] = useState("");
    // resize → longest edge
    const divide = [4,2,1];
    /*Promise.all(divide.map(
        async d => {
            const f = {
                path: "",
                w: Math.floor(Math.min(maxw, rec.width) / d),
                q: quality,
            };
            return [f.w, await fetch(`${API_URL}/assets/image/${}?m=thumbnail&w=${}&q=${quality}&o=1`)];
        }
    )).then(
        p=>p.map(([w, r]) => `${r.text()} ${w}w`).join(", ")
    ).then(srcSet => setImgSrcSet(srcSet));*/

    const imgSrcSet = divide.map(
         d => {
            const id = img._id;
            const f = {
                w: Math.floor(Math.min(maxw, img.width) / d),
                q: quality,
                o: 1
            };
            return `${API_URL}/assets/image/${id}?m=thumbnail&w=${f.w}&q=${f.q}&o=${f.o} ${f.w}w`;
        }).join(", ")

    return {sizes: sizesString, srcSet: imgSrcSet};
}